import * as React from "react";
import Layout from "../../components/Navigation/layout";
import {useEffect, useState} from "react";
import {Client, handleApiError} from "../../services/ApiService";
import {Container} from "react-bootstrap";

const IndexPage = () => {
    const [agb, setAgb] = useState('');

    useEffect(() => {
        Client.Raw.get('SiteConfig').then((res) => {
            setAgb(res.data[0].AGBs);
        }).catch(handleApiError);
    })

    return (
        <Layout pageTitle="AGBs">
            <Container>
                <h2>AGB</h2>
                <div dangerouslySetInnerHTML={{__html: agb}} />
            </Container>
        </Layout>
    )
}

export default IndexPage;
